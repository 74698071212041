/** Core dependencies */
import React, { useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
/** Third party dependencies & libraries */
import { Layout, Row, Col, Typography, Button, Card, notification, Image, Spin, Modal, Carousel, message, Divider } from 'antd';
import {
    WarningOutlined, DeleteOutlined, FieldTimeOutlined,
    CalendarOutlined, CompassOutlined, InfoCircleOutlined,
    BranchesOutlined, CheckCircleOutlined,
    ArrowLeftOutlined
} from '@ant-design/icons';
import moment from 'moment';
import { useDispatch } from 'react-redux';
/** Local dependencies & Libraries */
import { deleteEvent, getEventsByID } from '../../features/getEvents/getEvents';
/** Local Components */
import { Header } from '../../component/header/header';
import Sidebar from '../../component/siderbar/sidebar';
import Detail from '../../assets/images/detail.png';
import { Imports } from '../../imports';
import './details.css';
import BrokenImage from '../../assets/images/broken-image.png'
import { environment } from '../../Environments/environment';
import Slideshow from '../../component/Carrousel/Slideshow';
import ValidateModal from '../../component/modals/ValidateModal';
import { blockOptions, blockOptionsAPI } from '../../apiinteraction/apiinteraction';


const {
    yoloEndpoint,
} = environment;

const { flags } = Imports;


function Details(props) {

    const { Footer, Sider, Content } = Layout;

    const { Title } = Typography;

    const dispatch = useDispatch();

    const location = useLocation();

    const history = useHistory();

    const [events, setEvents] = useState({});

    const [eventsImages, setEventsImages] = useState([]);

    const [blockOptions, setBlockOptions] = useState([]);

    const [reloadFlag, setReloadFlag] = useState(false);

    const [alertImage, setAlertImage] = useState(BrokenImage);

    const [loader, setLoader] = useState(false)

    const [isVisible, setIsVisible] = useState(false)

    const [openValidationModal, setOpenValidationModal] = useState(false)

    const timeDuration = moment(events?.timeDuration, "HH:mm:ss")
    const hours = timeDuration.hours().toString().padStart(2, '0');
    const minutes = timeDuration.minutes().toString().padStart(2, '0');
    const seconds = timeDuration.seconds().toString().padStart(2, '0');
    const formattedTime = `${hours}h:${minutes}m:${seconds}s`;

    const { notifications, noButton } = location.state || {};

    // console.log({ notifications })

    const contentStyle = {
        height: '160px',
        color: '#fff',
        lineHeight: '160px',
        textAlign: 'center',
        background: '#364d79',
    };

    const validateMessage = (data) => {
        const args = {
            message: 'Error',
            description:
                `${data?.message}`,
            duration: 5,
        };
        notification.error(args);
    };


    const preReqFunctions = async () => {
        try {
            console.log("in event")
            setLoader(true)
            setEventsImages((events.notification?.image_path || location?.state?.imgPath || location?.state?.notification?.image_path)?.split(';'))
            setEvents(events.notification || location.state)
            // setAlertImage(
            //     `${yoloEndpoint}/${location.state.image}`
            // );
            setLoader(false)

        } catch (err) {
            setLoader(false)
            console.log('error')
        }

    }

    console.log(events)

    const fetchBlockOptions = async () => {

        const res = await blockOptionsAPI()
        if (!res?.message?.success) return
        let data = res.message?.blocks;
        setBlockOptions(data)

    }

    useEffect(() => {
        preReqFunctions()
        // fetchBlockOptions()
    }, [reloadFlag])

    const handleDetailClick = (id) => {
        history.push(`/dashboard`);
    };

    const deleteCurrEvent = async () => {

        try {

            setLoader(true)

            let data = {
                kpiType: events?.kpiType,
                trackId: events?.trackId,
                cameraName: events?.cameraName,
                validate: 'False'


            }
            console.log(events)

            console.log({ data })

            let result = await dispatch(deleteEvent(data))

            if (result.payload.success == true) {
                message.success('Successfully Deleted!')
                history.push('./dashboard')
            }
            else {
                validateMessage(result.payload.data);
            }
            setLoader(false)

        }
        catch (err) {
            console.log(err)
            setLoader(false)
        }
    }

    let detectionNameColor;
    if (events?.detectionName == 'Not Detected') {
        detectionNameColor = 'red';
    } else if (events?.detectionName == 'Unknown') {
        detectionNameColor = 'blue';
    } else {
        detectionNameColor = 'green';
    }


    useEffect(() => {
        preReqFunctions()
        // fetchBlockOptions()
    }, [reloadFlag])




    return (
        <div>

            <Layout>
                <Header />

                <Layout>

                    <Sidebar currentKey={"1"} />

                    <Spin className='loader-custom' style={{ display: loader == true ? "flex" : "none", justifyContent: 'center', alignItems: 'center' }} spinning={loader} size="large" />

                    {!loader
                        && <Content>
                            <div style={{ margin: '20px', padding: '20px', backgroundColor: 'white', borderRadius: '20px', maxHeight: 'fit-content', minHeight: '75vh' }}>
                            <Button className='custom-button-blue-back' onClick={handleDetailClick}><ArrowLeftOutlined />Back</Button>
                                <Row>
                                    <Modal
                                        title="Confirmation"
                                        width='350px'
                                        mask={false}
                                        closable={null}
                                        onCancel={() => setIsVisible(false)}
                                        visible={isVisible}
                                        footer={null}
                                        header={null}>
                                        <Row className='confirmation'>
                                            Are you sure ?
                                            <Row className='confirmation-buttons'>
                                                <Button className='btn-1' onClick={deleteCurrEvent}>Yes</Button>
                                                <Button className='btn-2' onClick={() => setIsVisible(false)}>No</Button>
                                            </Row>
                                        </Row>
                                    </Modal>
                                </Row>




                                <Row className=''>
                                    <Col md={24}>
                                        <Title level={2}>Event Details</Title>
                                        <Divider />

                                    </Col>

                                    <Col style={{ display: "flex", justifyContent: "center", alignItems: "center" }} sm={24} lg={12}>
                                        <Slideshow images={eventsImages} />
                                    </Col>
                                    <Col span={1} />

                                    <Col style={{ alignItems: 'center', display: 'flex' }} sm={24} md={24} lg={9}>
                                        <div
                                            className='styling-card'>
                                            <Row style={{ width: '100%', height: '40vh' }} >
                                                <Col style={{ textAlign: 'left' }} span={24}>

                                                <Row>
                                                    <Col span={12}>
                                                            <Title className='event-icon' level={5}>
                                                                <BranchesOutlined className='mr-1' />
                                                                {/* UID */}
                                                                Location
                                                            </Title>
                                                            <p className='event-text'>
                                                                {events?.notification?.location || notifications?.location || events?.cameraName}

                                                            </p>

                                                        </Col>
                                                    {/* </Row>

                                                    <Row style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly' }}> */}
                                                        <Col span={12}>
                                                            <Title className='event-icon' level={5}>
                                                                <InfoCircleOutlined className='mr-1' />
                                                                {/* Event */}
                                                                <strong>{notifications?.detection_name || events.kpiType || events?.notification?.detection_name} </strong>
                                                            </Title>
                                                            <p className='event-text'>
                                                                <Col style={{ textOverflow: 'ellipsis', overflow: 'hidden' }} span={24}>

                                                                    <span style={{ color: detectionNameColor }}>
                                                                        {events?.detectionName}
                                                                    </span>
                                                                </Col>
                                                            </p>
                                                        </Col>
                                                    </Row>

                                                    <Row style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly' }}>
                                                        <Col span={12}>
                                                            <Title className='event-icon' level={5}>
                                                                <CalendarOutlined className='mr-1' />
                                                                DetectionDate
                                                            </Title>
                                                            <p className='event-text'>
                                                                {moment(notifications?.date_time?.split(' ')[0] || events?.notification?.date_time?.split(' ')[0] || events?.detectionDate).format('Do MMMM, YYYY')}
                                                            </p>
                                                        </Col>
                                                        <Col span={12}>
                                                            <Title className='event-icon' level={5}>
                                                                <FieldTimeOutlined className='mr-1' />
                                                                {/* Time */}
                                                                Time Duration
                                                            </Title>
                                                            <p className='event-text'>{events.endTime ? formattedTime : "Ongoing"}</p>
                                                        </Col>
                                                        <Col span={12}>
                                                            <Title className='event-icon' level={5}>
                                                                <FieldTimeOutlined className='mr-1' />
                                                                {/* Time */}
                                                                Start Time
                                                            </Title>
                                                            //<p className='event-text'>{moment(notifications?.date_time?.split(' ')[1] || events?.notification?.date_time?.split(' ')[1] || events.startTime, "HH:mm:ss" || 'N/A').format('hh:mm:ss A')}</p>
                                                            <p className='event-text'>{notifications?.date_time?.split(' ')[1] || events?.notification?.date_time?.split(' ')[1] || moment(events.startTime, "HH:mm:ss" || 'N/A').format('hh:mm:ss A')}</p>
                                                        </Col>
                                                        <Col span={12}>
                                                            <Title className='event-icon' level={5}>
                                                                <FieldTimeOutlined className='mr-1' />
                                                                {/* Time */}
                                                                End Time
                                                            </Title>
                                                            <p className='event-text'>{events.endTime ? moment(events.endTime, "HH:mm:ss" || 'N/A').format('hh:mm:ss A') : "Ongoing"}</p>
                                                        </Col>




                                                    </Row>
                                                    <Row>
                                                        {noButton
                                                            ? null
                                                            :
                                                            <>
                                                                <Col md={12} sm={24} >
                                                                    <Button onClick={() => setIsVisible(true)} className='custom-button-red' ><DeleteOutlined /> Delete Alert</Button>
                                                                </Col>
                                                                <Col md={12} sm={24}>
                                                                    <Button
                                                                        onClick={() => setOpenValidationModal(true)}
                                                                        className='custom-button-blue' >
                                                                        <CheckCircleOutlined /> Validate Alert</Button>
                                                                    <ValidateModal
                                                                        block_options={blockOptions}
                                                                        reloadFlag={reloadFlag}
                                                                        reloadPage={setReloadFlag}
                                                                        data={events}
                                                                        isVisible={openValidationModal}
                                                                        onCancel={() => setOpenValidationModal(false)} />
                                                                </Col>
                                                            </>
                                                        }

                                                    </Row>

                                                </Col>

                                            </Row>
                                        </div>
                                    </Col>
                                </Row>

                            </div>
                        </Content>}

                </Layout>
            </Layout>

        </div >
    )
}

export default Details
