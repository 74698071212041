import React, { useEffect, useState } from 'react'
import { Layout, Menu, Image, Row } from 'antd';
import { ExportOutlined, UserOutlined,BarChartOutlined, UsergroupAddOutlined, AppstoreFilled, LogoutOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import './sidebar.css'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Logo from '../../assets/images/logoHeader.png'
import Title from 'antd/lib/skeleton/Title';

const { Header, Content, Footer, Sider } = Layout;


function Sidebar(props) {

    const history = useHistory()

    const handleLogout = () => {
        // history.push('/')
        localStorage.clear()
    }



    return (
        <div>
            <Sider
                breakpoint="lg"
                style={{ height: '100%', background: 'white' }}
                collapsedWidth="0"
                onBreakpoint={broken => {
                    // console.log(broken)

                }}
                onCollapse={(collapsed, type) => {
                    // console.log(collapsed, type);
                }}
                className="custom-sidebar"
            >

                <div className="logo" />
                <div style={{ padding: '20px' }}>
                    <Image preview={false} src={Logo} />
                </div>
                <Menu className="pt-5" mode="inline" defaultSelectedKeys={[props.currentKey]}>
                    <Menu.Item key="1" icon={<AppstoreFilled className="font-24" />}>
                        <Link to='../dashboard'>
                            Dashboard
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="2" icon={<UserOutlined className="font-24" />}>
                        <Link to='../manual-entry'>
                            Manual Entry
                        </Link>
                    </Menu.Item>
                      <Menu.Item key="4" icon={<BarChartOutlined className="font-24" />}>
                        <Link to='../analytics'>
                            Analytics
                        </Link>
                    </Menu.Item>
                    <Menu.Item onClick={() => { localStorage.clear() }} key="3" icon={<LogoutOutlined className="font-24" />}>
                        <Link to={'../'} >
                            Log out
                        </Link>
                    </Menu.Item>
                </Menu>
            </Sider>
        </div>
    )
}

export default Sidebar
