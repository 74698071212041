import React, { useEffect, useState } from 'react'
import {
    Layout, Row, Col, Typography, Button, Card, Spin, Form, Select, Upload, DatePicker, message, notification, Divider, Tag,
    Input,
} from 'antd';
// import Header from '../../component/header/header';
import { Header } from '../../component/header/header';
import { UploadOutlined, CheckOutlined, DeleteOutlined } from '@ant-design/icons';
import Sidebar from '../../component/siderbar/sidebar';
import { createEvent, uploadImage } from '../../features/getEvents/getEvents';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { uploadImageApi } from '../../apiinteraction/apiinteraction';
import moment from 'moment';
import Papa from 'papaparse';
import filePath from '../../csvFile/combined.csv';


function ManualEntry() {

    const validateMessage = (data) => {
        const args = {
            message: 'Error',
            description:
                `${data?.message}`,
            duration: 5,
        };
        notification.error(args);
    };

    const [form] = Form.useForm()

    const { Footer, Sider, Content } = Layout;

    const { Title } = Typography;

    const { Option } = Select;

    const { RangePicker } = DatePicker;

    const dispatch = useDispatch();

    const history = useHistory();

    const [region, setRegion] = useState('');

    const [flag, setFlag] = useState('')

    const [timestamp, setTimestamp] = useState(Date())

    const [resURL, setResURL] = useState("")

    const [loader, setLoader] = useState(false)

    const [filelist, setFilelist] = useState(null)

    const [startTime, setStartTime] = useState(null);
    const [binaryImage, setBinaryImage] = useState(null);

    const [csvdata, setCsvData] = useState([]);
    const [allData, setAllData] = useState([]);
    const [uniqueCameraNames, setUniqueCameraNames] = useState([]);
    const [kpiType, setKpiType] = useState(null);

    const handleKpiTypeChange = (value) => {
        setKpiType(value);
    };


    useEffect(() => {
        fetch(filePath)
            .then(response => response.text())
            .then(csvString => {
                Papa.parse(csvString, {
                    header: true,
                    dynamicTyping: true,
                    complete: (result) => {
                        const parsedData = result.data.map(row => {
                            const filledRow = {};
                            result.meta.fields.forEach(field => {
                                filledRow[field] = row[field] !== undefined ? row[field] : null;
                            });
                            return filledRow;
                        });
                        setCsvData(parsedData);
                        setAllData(parsedData);
                        extractUniqueCameraNames(parsedData);

                    },
                });
            })
            .catch(error => console.error('Error fetching the CSV file:', error));
    }, [filePath]);

    const extractUniqueCameraNames = (data) => {
        const cameraNames = data.map(item => item.cameraName);
        const uniqueNames = [...new Set(cameraNames)];
        setUniqueCameraNames(uniqueNames);
    };

    let filePasted = []
    const onFinish = async (values) => {

        const formData = new FormData();
        console.log({ values })
        formData.append('image', values?.uploadImage);
        formData.append('hasWpn', values?.hasWpn || "False");
        formData.append('cameraName', values?.cameraName);
        formData.append('detectionName', values?.detectionName);
        formData.append('kpiType', values?.kpiType);
        formData.append('startTime', moment(values?.startTime.toISOString()).format('DD-MM-YYYY HH:mm:ss'));
        formData.append('endTime', moment(values?.endTime.toISOString()).format('DD-MM-YYYY HH:mm:ss'));

        try {
            setLoader(true);
            let result = await dispatch(createEvent(formData));
            if (result.payload.success == true) {
                history.push('./dashboard');
            } else {
                validateMessage(result.payload.data);
            }
            setLoader(false);
        } catch (err) {
            console.log(err);
            setLoader(true);
        }
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
        console.log(resURL)
    };


    function handleChangeLocation(value) {
        setRegion(value)
        console.log(value)
    }


    function handleChangeFlag(value) {
        setFlag(value)
        console.log(value)
    }



    function onChangeDate(value, dateString) {
        // console.log('Selected Time: ', value);
        // console.log('Formatted Selected Time: ', dateString);
        setTimestamp(value)
    }

    function onOk(value) {
        // console.log('onOk: ', value);
        setTimestamp(value)
    }

    const imageTypes = ['png', 'PNG', 'jpg', 'JPG', 'JPEG', 'jpeg']

    const [errorFlag, setErrorFlag] = useState(false)
    console.log(filelist);


    const handleUpload = async options => {

        setErrorFlag(false)
        setLoader(true)


        let { onSuccess, onError, file, onProgress } = options;
        let fileType = file.type.split('/')
        if (!imageTypes.includes(fileType[1])) {
            setLoader(false)
            validateMessage({ message: "Only Images are allowed!" })
            return
        }

        // const isLt2M = file.size / 1024 / 1024 < 2;

        try {
            console.log(file);

            let result = await uploadImageApi(file)
            console.log({ result }, 'new result');
            message.success('Image submitted successfully');
            setErrorFlag(true)
            // setResURL(result.message)

            form.setFieldsValue({
                uploadImage: file,
            })

        } catch (err) {
            // console.log("Eroor: ", err);
            message.error('Error uploading image');

        }

        setLoader(false)
    };

    const handleCopyPasteImage = async (file) => {

        setErrorFlag(false)
        setLoader(true)


        let fileType = file.type.split('/')
        if (!imageTypes.includes(fileType[1])) {
            setLoader(false)
            validateMessage({ message: "Only Images are allowed!" })
            return
        }


        try {
            console.log(file);

            let result = await uploadImageApi(file)
            console.log({ result });
            message.success('Image submitted successfully');
            setErrorFlag(true)
            form.setFieldsValue({
                uploadImage: result.message
            })

        } catch (err) {
            message.error('Error uploading image');

        }

        setLoader(false)
    };

    const handleDelete = () => {
        form.setFieldsValue({
            uploadImage: null
        })
        setErrorFlag(false)
        setResURL('')
    }


    useEffect(() => {
        const handlePaste = (event) => {
            const items = event.clipboardData.items;
            console.log(items)
            if (items?.length > 1) return message.error("Bulk image upload not allowed")
            for (let i = 0; i < items.length; i++) {
                const item = items[i];
                if (item.kind === 'file') {
                    const file = item.getAsFile();
                    // Upload the file here using your upload logic
                    // setFilelist(file)
                    console.log(i + 'Pasting file:', file);
                    handleCopyPasteImage(file)
                    // You can use your custom logic to upload the file, e.g., via the Ant Design Upload component or a separate API call.
                }
            }
        };

        // Add the paste event listener
        document.addEventListener('paste', handlePaste);

        // Remove the event listener when the component unmounts
        return () => {
            document.removeEventListener('paste', handlePaste);
        };
    }, []);



 const disabledEndDate = (current) => {
        if (!startTime) {
            return current && current > moment().endOf('day'); // Disable future dates
        }
        return current && (current > moment().endOf('day') || current < startTime.startOf('day'));
    };

    return (
        <div>

            <Layout>
                <Header />

                <Layout>

                    <Sidebar currentKey={"2"} />

                    <Content style={{ padding: '20px' }}>

                        <Spin className='loader-custom' style={{ display: loader == true ? "block" : "none", height: "80vh", width: "85%" }} spinning={loader} size="large" />

                        <Row>
                            {console.log(csvdata, 'CSVdATA')}
                            <Card title={<Title className='blue-color' level={2}>Event Form (Manual Intervention)</Title>} className='w-100 border-radius-20 '>
                                <Form
                                    style={{ maxWidth: '500px' }}
                                    name="basic"
                                    labelCol={{ span: 24 }}
                                    wrapperCol={{ span: 24 }}
                                    initialValues={{ remember: false }}
                                    onFinish={onFinish}
                                    onFinishFailed={onFinishFailed}
                                    autoComplete="off"
                                    className='ml-2'
                                    form={form}
                                >



                                    {/* <Form.Item
                                        label="Camera Name"
                                        name="cameraName"

                                    >
                                        <Input className='w-100' placeholder="Camera Name" />

                                    </Form.Item> */}
                                    <Form.Item
                                        label="Camera Name"
                                        name="cameraName"
                                    >
                                        <Select className='w-100' placeholder="Select Camera Name">
                                            {uniqueCameraNames.map((name, index) => (
                                                <Option key={index} value={name}>{name}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                    <Form.Item
                                        label="Detection Name"
                                        name="detectionName"

                                    >
                                        <Input className='w-100' placeholder="Detection Name" />

                                    </Form.Item>

                                    <Form.Item
                                        label="Type Kpi"
                                        name="kpiType"
                                        rules={[{ required: true, message: 'Please select KPI!' }]}
                                    >
                                        <Select className='w-100' onChange={handleKpiTypeChange}>
                                            <Option value="BaggageDetection">Baggage Detection</Option>
                                            <Option value="NumberPlate">Number Plate</Option>
                                            <Option value="FaceRecognition">Face Recognition</Option>
                                            <Option value="Fire&Smoke Detection">Fire & Smoke Detection</Option>
                                        </Select>
                                    </Form.Item>

                                    {kpiType === 'BaggageDetection' && (
                                        <Form.Item
                                            label="Has Baggage"
                                            name="hasWpn"
                                            rules={[{ required: true, message: 'Please select Has Baggage!' }]}
                                        >
                                            <Select className='w-100'>
                                                <Option value="True">True</Option>
                                                <Option value="False">False</Option>
                                            </Select>
                                        </Form.Item>
                                    )}


                                    <Form.Item
                                        label="Start Time"
                                        name="startTime"
                                        rules={[{ required: true, message: 'Please select Start Time!' }]}
                                    >
                                        <DatePicker
                                            disabledDate={(current) => {
                                                return current && current > moment(new Date(), "DD-MM-YYYY");
                                            }}
                                            className='w-100'
                                            showTime
                                            format="DD-MM-YYYY HH:mm:ss"
                                            placeholder="Start Time"
                                            onChange={(value) => setStartTime(value)}
                                        />
                                    </Form.Item>
                                    {/* <Form.Item
                                        label="End Time"
                                        name="endTime"
                                        rules={[{ required: true, message: 'Please select End Time!' }]}
                                    >
                                        <DatePicker
                                            disabledDate={(current) => {
                                                return current && current > moment(new Date(), "YYYY-MM-DD");
                                            }}
                                            className='w-100'
                                            showTime
                                            format="DD-MM-YYYY HH:mm:ss"
                                            placeholder="End Time"
                                            disabledTime={disabledEndTime}
                                        />
                                    </Form.Item> */}
<Form.Item
                                        label="End Time"
                                        name="endTime"
                                        rules={[
                                            { required: true, message: 'Please select End Time!' },
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    if (!value || getFieldValue('startTime') && value.isSameOrBefore(getFieldValue('startTime'))) {
                                                        return Promise.reject('End time must be after start time!');
                                                    }
                                                    return Promise.resolve();
                                                },
                                            }),
                                        ]}
                                    >
                                        <DatePicker
                                            disabledDate={disabledEndDate}
                                            className='w-100'
                                            showTime
                                            format="DD-MM-YYYY HH:mm:ss"
                                            placeholder="End Time"
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        label="Upload Image"
                                        name="uploadImage"
                                        rules={[{ required: true, message: 'Please Insert Image!' }]}
                                    >

                                        <Upload
                                            customRequest={handleUpload}
                                            onProgress={false}
                                            className='upload-image-icon-custom'
                                            showUploadList={false}
                                        >
                                            {!errorFlag &&
                                                <div style={{ display: "flex", justifyContent: "center", alignItems: "flex-end", gap: "10px" }}>
                                                    <Button icon={<UploadOutlined />}>Upload</Button>
                                                    <span style={{ fontSize: "12px" }}>drag & drop or paste image</span>
                                                </div>
                                            }

                                        </Upload>
                                        {errorFlag &&
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                <div style={{ border: "1px solid #27b824", borderRadius: "10px", padding: "10px", backgroundColor: "#27b824", color: "white" }}>
                                                    <CheckOutlined />
                                                    Uploaded
                                                </div>
                                                <Button onClick={handleDelete} className="ml-1" icon={<DeleteOutlined />}>
                                                    Delete
                                                </Button>
                                            </div>}


                                    </Form.Item>

                                    <Form.Item wrapperCol={{ span: 24 }}>
                                        <Button className='custom-button mt-2' type="primary" htmlType="submit">
                                            Submit
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </Card>
                        </Row>

                    </Content>

                </Layout>
            </Layout>

        </div >
    )
}

export default ManualEntry
