import { getToken, onMessage } from "firebase/messaging";
import firebase from './firebase.config'; // Your Firebase configuration
import { message } from "antd";

// Function to generate Firebase token
export const firebaseTokenGenerator = async () => {
    try {
        let currentToken = await getToken(firebase?.messaging, {
            vapidKey: 'BDtrWTALqz9AMio7KYieEjJp9ahpw2o_oF3Z8PtM_OSojXsnOOXPPyWIfiNV_JgGOfvDl8Iw1c8srKbyjmmXRiI',
        });
        return currentToken;
    } catch (error) {
        console.error('Error generating Firebase token:', error);
        return null;
    }
};

// Function to request notification permissions and get token
export const requestPermission = async () => {
    try {
        const permission = await Notification.requestPermission();
        if (permission === "granted") {
            const token = await firebaseTokenGenerator();
            if (token) {
                console.log({ token });
                /* We can send the token to the server */
                localStorage.setItem('firebaseToken', token);
                return token;
            } else {
                console.error('No token received.');
            }
        } else if (permission === "denied") {
            message.error("Please allow notifications.");
        }
    } catch (error) {
        console.error('Error requesting notification permission:', error);
    }
};


