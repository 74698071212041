import React from 'react'
import { Form, Row, Col, Image, Typography, Button, Input, notification, Spin } from 'antd';
import Logo from '../../assets/images/logo.png'

import './sider.css'
import { Link } from 'react-router-dom';

function Sider() {

    const { Title, Text } = Typography;


    return (
        <div>
            <Spin spinning={false} className="loader" size={'large'} />
            <Row style={{ justifyContent: 'center' }}>
                <Col className="login-cover-image hide-on-mobile" span={24}>
                    <Row >
                        <Row className="logo-row" >
                            <Link to='../'>
                                <Image style={{ width: '154px', height: '50px' }} src={Logo} />
                            </Link>
                        </Row>
                        <Row className="login-page-content">
                            <Title className="text-white login-heading">
                                Solutions for Security,
                                Access, Safety, Search
                                and Health
                            </Title>
                            <p className="text-white login-para">AI-enabled Video Intelligence Platform.</p>
                        </Row>
                    </Row>
                </Col>
            </Row>
        </div>
    )
}

export default Sider
