import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import thunk from 'redux-thunk';
import counterReducer from '../features/counter/counterSlice';
import getEvents from '../features/getEvents/getEvents';
import auth from "../features/auth/auth";


import {
  environment,
} from '../Environments/environment';

const {
  envName,
} = environment;

const reducers = combineReducers({
  counter: counterReducer,
  login: auth,
  getEvents: getEvents
});

const persistConfig = {
  key: 'root',
  storage,
};


const persistedReducer = persistReducer(persistConfig, reducers);
// const persistedReducer2 = persistReducer(persistConfig2, reducers);


const store = configureStore({
  reducer: persistedReducer,
  devTools: envName !== 'production',
  middleware: [thunk],
});

export default store;