/** Core dependenices */
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';


/** Third party dependencies */
import { Card, Row, Typography, Col, Image, Button, Divider, Tag } from 'antd';
import { WarningOutlined, EyeOutlined, DeleteOutlined } from '@ant-design/icons';
import moment from 'moment';

import ImageChecked from '../../assets/images/checked.png'
/** Local imports and assets */
import CoverImage from '../../assets/images/coverCard.png'
import BrokenImage from './images/broken-image.png'

import './alertCard.css'
import { Imports } from '../../imports';

import { environment } from '../../Environments/environment';
import "./alertCard.css"

const {
    yoloEndpoint,
} = environment;

const {
    flags,
} = Imports;



function AlertCard(props) {

    console.log(props)
    const { Meta } = Card;
    const { Title } = Typography;

    const history = useHistory();

    const [events, setEvents] = useState({});
    const [eventImage, setEventImage] = useState(BrokenImage);

    useEffect(async () => {
        try {
            const {
                data,
                data: {
                    image
                }
            } = props;

            setEvents(data);
            setEventImage(
                `${yoloEndpoint}/${events?.imgPath}`
            );

          
        } catch (exc) {
            setEventImage(BrokenImage)
            console.log(exc);
        }
    }, []);


    const onClickView = (data) => {

        // props.saveFilters()
        localStorage.setItem('eventId', `event-${props.index}`)
        history.push({
            pathname: './details',
            state: events
        })

    }

    let detectionNameColor;
    if (events?.detectionName == 'Not Detected') {
        detectionNameColor = 'red';
    } else if (events?.detectionName == 'Unknown') {
        detectionNameColor = 'blue';
    } else {
        detectionNameColor = 'green';
    }

    return (
        <div id={`event-${props?.index}`} className='alertCard'>

            <Card
                hoverable
                style={{
                    width: '100%',
                    borderRadius: '5px',
                    boxShadow: '1px 1px 5px -1px #555',
                    padding: "5px",
                    borderRadius: '10px',
                    position: 'relative',
                    paddingBottom: '40px'
                }}
                onClick={() => onClickView(props?.data)}
            >
                <div style={{ width: "100%" }}>
                    <Row style={{ display: "flex", flexDirection: 'column', alignItems: 'center' }}>
                        <Image
                            className='cover-image'
                            // style={{ borderRadius: "15px", padding: '3px', objectFit: 'cover' }}
                            style={{ borderRadius: "15px", padding: '3px', objectFit: 'cover', objectPosition: props?.data?.kpiType === "Number Plate" ? 'bottom' : 'top' }}
                            alt=" No Image found"
                            src={props?.data?.imgPath ? require(`../../static/Images/${(props?.data?.imgPath).split(';')[0]}.jpg`) : BrokenImage}
                            preview={false}
                            height={250}
                            width="100%"
                        />

                    </Row>
                 
                    <Row>
                        {props?.data?.manualEntry == "True" ? (
                            <Col span={10}>
                                <Tag style={{ marginTop: '10px' }} color="orange">
                                    Manual Entry
                                    {props?.data?.validatedEntry == "True" && (
                                        <Image src={ImageChecked} width={15} style={{ marginLeft: '5px' }} />
                                    )}
                                </Tag>
                            </Col>
                        ) : (
                            <Col span={10}>
                                <Tag style={{ marginTop: '10px' }} color="blue">
                                    Detected by AI
                                    {props?.data?.validatedEntry == "True" && (
                                        <Image src={ImageChecked} width={15} style={{ marginLeft: '5px' }} />
                                    )}
                                </Tag>
                            </Col>
                        )}
                    </Row>
                    <Row>
                        <Col className='mt-1' span={20}>
                            <Row>

                                <Col style={{ textOverflow: 'ellipsis', overflow: 'hidden' }} span={24}>
                                    {props?.data?.flag?.toUpperCase()}
                                </Col>
                            </Row>
                        </Col>
                        <Col className='' span={23}>
                            <Row>
                                <Col style={{ textOverflow: 'ellipsis', overflow: 'hidden' }} span={24}>
                                    {props?.data?.kpiType && (
                                        <strong style={{ fontSize: '13px' }}>{props?.data.kpiType}: </strong>
                                    )}
                                    <span style={{ color: detectionNameColor, fontSize: '11px' }}>
                                        {props?.data?.detectionName}
                                    </span>
                                    {props?.data?.kpiType === 'Baggage Detection' && (
                                        <span style={{ fontSize: '11px' }}>
                                            {props?.data?.hasWpn == "True" ? <strong> (Has Baggage)</strong> : <strong> (No Baggage)</strong>}
                                        </span>
                                    )}
                                </Col>
                            </Row>
                            <Row>
                                <Col style={{ textOverflow: 'ellipsis', overflow: 'hidden' }} span={24}>
                                    <strong style={{ fontSize: '13px' }}>Location:</strong>
                                    <span style={{ fontSize: '12px' }}> {props?.data?.cameraName || 'N/A'}</span>
                                </Col>
                            </Row>

                        </Col>

                        <Col span={20}>
                            <Row>
                                <Col style={{ textOverflow: 'ellipsis', overflow: 'hidden' }} span={24}>
                                <strong style={{ fontSize: '13px' }}>Date:</strong>
                                <span style={{ fontSize: '12px' }}> {moment(events.detectionDate, "DD/MM/YYYY" || 'N/A').format('Do MMMM, YYYY')}</span>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={20}>
                            <Row>
                                <Col style={{ textOverflow: 'ellipsis', overflow: 'hidden' }} span={24}>
                                <strong style={{ fontSize: '13px' }}>Time:</strong>
                                <span style={{ fontSize: '12px' }}> {moment(events?.startTime, "HH:mm:ss" || 'N/A').format('hh:mm:ss A')}</span>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
                <div className='' style={{ display: "flex", justifyContent: "center", position: 'absolute', bottom: '10px', boxSizing: 'border-box', width: '100%' }}>
                    <Col className='text-center' span={13}>
                        <Button className='no-border'  ><EyeOutlined /> View Alert</Button>
                    </Col>
                </div >
            </Card >
        </div >
    )
}

export default AlertCard
