import React from 'react'
import { Counter } from '../features/counter/Counter';
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import Login from '../auth/login/login';
import Sider from '../component/sider/sider';
import Header from '../component/header/header';
import Sidebar from '../component/siderbar/sidebar';
import Dashboard from '../pages/dashboard/dashboard';
import AlertCard from '../component/alertCard/alertCard';
import Details from '../pages/details/details';
import ManualEntry from '../pages/manualEntry/manualEntry';
import Analytics from '../pages/Analytics/Analytics';

function Routing({ notifications }) {

    return (
        <div>
            <Router>
                <Switch>

                    <Route path='/counter' >
                        <Counter />
                    </Route>

                    <Route exact path='/'>
                        <Login />
                    </Route>

                    <Route exact path='/dashboard'>
                        <Dashboard notifications={notifications} />
                    </Route>

                    <Route exact path='/details'>
                        <Details />
                    </Route>

                    <Route exact path='/manual-entry'>
                        <ManualEntry />
                    </Route>

                    {/* components  */}
                    <Route path='/sider'>
                        <Sider />
                    </Route>

                    <Route path='/sidebar'>
                        <Sidebar />
                    </Route>

                    <Route path='/card'>
                        <AlertCard />
                    </Route>

                    <Route exact path='/analytics'>
                        <Analytics />
                    </Route>
                </Switch>
            </Router>
        </div>
    )
}

export default Routing
