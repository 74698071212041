import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Layout, Row, Col, Typography, Button, Card, Input, notification, Spin, Space, Divider, Form, Select, Badge, Drawer, List } from 'antd';
import { Header } from '../../component/header/header';
import Sidebar from '../../component/siderbar/sidebar';
import { useDispatch } from 'react-redux';

import { BellOutlined, HistoryOutlined, SearchOutlined } from '@ant-design/icons';
import './dashboard.css'
import AlertCard from '../../component/alertCard/alertCard';
import { getAllEvents } from '../../features/getEvents/getEvents';
import { useHistory } from 'react-router-dom';
import Modal from '../../component/modals/CustomModal';
import CustomModal from '../../component/modals/CustomModal';
import moment from 'moment';
import { blockOptionsAPI } from '../../apiinteraction/apiinteraction';
import Papa from 'papaparse';
import filePath from '../../csvFile/combined.csv';
import { findByTestId } from '@testing-library/react';



const Context = React.createContext({
    name: 'Default',
});

function Dashboard({ notifications }) {

    const { Footer, Sider, Content } = Layout;
    const [form] = Form.useForm()
    const { Title } = Typography;
    let a = [1, 2, 3, 4, 5, 6, 7, 5];
    const dispatch = useDispatch();
    const history = useHistory();
    const { Option } = Select;


    const validateMessage = (data) => {
        const args = {
            message: 'Error',
            description:
                `${data?.message}`,
            duration: 5,
        };
        notification.error(args);
    };


    const [ViewHistory, setViewHistory] = useState(false)

    function onClickView() {
        setViewHistory(true)
        console.log('clicked')

        window.scrollTo({
            top: 60000,
            left: 0,
            behavior: 'smooth'
        });
    }

    const [loader, setLoader] = useState(false)

    const [noData, setNoData] = useState(false)

    const [events, setEvents] = useState([])

    const [limit, setLimit] = useState(50)

    const [offset, setOffset] = useState(0)

    const [totalRecords, setTotalRecords] = useState(0)

    const [isVisible, setIsVisible] = useState(false)

    const [queryValues, setQueryValues] = useState({})

    const [csvdata, setCsvData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [allData, setAllData] = useState([]);
    const [cameraNames, setCameraNames] = useState([]);
    const [kpi, setKpi] = useState();

    const [filters, setFilters] = useState({
        cameraName: null,
        kpiType: null,
        detectionDate: null,
        isCustom: null,
        validatedEntry: null
    })

    useEffect(() => {

        fetch(filePath)
            .then(response => response.text())
            .then(csvString => {
                Papa.parse(csvString, {
                    header: true,
                    dynamicTyping: true,
                    complete: (result) => {

                        setLoading(true)
                        const parsedData = result.data.map(row => {
                            const filledRow = {};
                            result.meta.fields.forEach(field => {
                                filledRow[field] = row[field] !== undefined ? row[field] : null;
                            });
                            return filledRow;
                        });
                        const updatedCsvData = [...parsedData];
                        updatedCsvData.pop();

                        setCsvData(updatedCsvData);
                        setAllData(parsedData);

                        const uniqueCameraNames = [...new Set(parsedData.map(item => item.cameraName))];
                        setCameraNames(uniqueCameraNames);
                        setLoading(false)

                    },
                });
            })
            .catch(error => console.error('Error fetching the CSV file:', error));
            
    }, [filePath]);

    const setPreviousFilters = () => {

        const { timestamp = null, ...filters } = (JSON.parse(localStorage?.getItem('dashboard-filters')) || {})
        let filtersToSet = {}
        if (timestamp)
            filtersToSet = { ...filters, timestamp: [moment(timestamp[0]), moment(timestamp[1])] }
        else
            filtersToSet = { ...filters }
        setQueryValues({ ...filtersToSet })
        form.setFieldsValue({ ...filtersToSet })

    }
    const onFinish = (values) => {
        if (filters?.cameraName === null && filters?.kpiType === null && filters?.detectionDate === null && filters?.isCustom === null && filters?.validatedEntry === null) {
            setIsVisible(false)
            allData.pop()
            return setCsvData(allData)
        }
        setLoading(true)
        Object.keys(filters).forEach((key) => {
            if (filters[key] == null) delete filters[key]
        })
        let temp = allData

        temp = temp?.filter(item => {
            let isValid = true;

            if (filters?.cameraName) {
                isValid = isValid && item.cameraName?.toLowerCase() === filters.cameraName.toLowerCase();
            }
            if (filters?.kpiType) {
                isValid = isValid && item.kpiType == filters.kpiType;
            }
            if (filters?.detectionDate) {
                isValid = isValid && moment(item?.detectionDate).isSame(filters?.detectionDate);
            }
            if (filters?.isCustom !== undefined) {
                isValid = isValid && item.manualEntry == (filters.isCustom ? 'True' : 'False');
            }
            if (filters?.validatedEntry !== undefined) {
                isValid = isValid && item.validatedEntry == (filters.validatedEntry ? 'True' : 'False');
            }

            return isValid;
        });

        setCsvData(temp)
        setIsVisible(false)
        setLoading(false)

    }


    const clearFilters = () => {
        setFilters({
            cameraName: null,
            kpiType: null,
            detectionDate: null,
            isCustom: null,
            validatedEntry: null
        })
        form.resetFields()
    }


    let modalState = {
        isVisible: isVisible,
        onFinish: onFinish,
        onCancel: () => setIsVisible(false),
        form: form,
        clearFilters: clearFilters,
        setFilters
    }
    const onClickLoadMore = () => {
        setOffset(offset + limit)
    }

    const handleKpiTypeChange = (value) => {
        setFilters(prevFilters => ({
            ...prevFilters,
            kpiType: value
        }));
    };

    const onFinishFilter = () => {
        setLoading(true);

        let filteredData = allData;

        if (filters.kpiType) {
            filteredData = filteredData?.filter(item => item.kpiType === filters.kpiType);
        }
        console.log(filteredData, 'filterdATA')
        setCsvData(filteredData);
        setLoading(false);
    };

    const [notificationCount, setNotificationCount] = useState(0); // State to track notification count
    const [drawerVisible, setDrawerVisible] = useState(false); // State to control drawer visibility

    const [api, contextHolder] = notification.useNotification();
    const [notificationList, setNotificationList] = useState([]); // State to track unique notifications

    const processedKeys = useRef(new Set()); // Ref to track processed composite keys


    // Show drawer
    const showDrawer = () => {
        setDrawerVisible(true);
    };

    // Close drawer
    const closeDrawer = () => {
        setDrawerVisible(false);
    };

    // Function to create a unique key from track_id, location, and detection_name
    const createUniqueKey = (notification) => {
        return `${notification.track_id}_${notification.location}_${notification.detection_name}`;
    };


    useEffect(() => {
        // Process incoming notifications
        if (notifications && notifications.length > 0) {
            notifications.forEach(notification => {
                console.log('detection_name:', notification.detection_name);

                // Create a unique composite key
                const uniqueKey = createUniqueKey(notification);

                // Check if the unique key has already been processed
                if (!processedKeys.current.has(uniqueKey)) {
                    // Check for different detection names and handle accordingly
                    if (["Fire & Smoke", "Smoke", "Fire"].includes(notification.detection_name) && notification.track_id) {
                        setKpi(notification); // Set kpi to the current notification
                        setNotificationList(prevList => [...prevList, notification]); // Add unique notification
                        setNotificationCount(prevCount => prevCount + 1); // Increment notification count
                        openNotification('topRight', notification); // Pass the entire notification object
                    }

                    // Add the unique key to the processed set to prevent future duplicates
                    processedKeys.current.add(uniqueKey);
                } else {
                    console.log(`Notification with key ${uniqueKey} already processed.`);
                }
            });
        }
    }, [notifications]);

    console.log(kpi);

    // Function to open the notification
    const openNotification = (placement, notificationData) => {
        // If the drawer is open, don't show the notification popup
        if (drawerVisible) {
            return;
        }

        api.info({
            message: `${notificationData.detection_name} Alert!` || 'Unknown', // Use the passed detectionName
            placement,
            onClick: () => {
                // Push only the specific notification data related to the clicked track_id
                history.push({
                    pathname: '/details',
                    state: { notification: notificationData, noButton: true } // Pass the entire notification object
                });
            }
        });
    };

    // Memoize the context value to avoid unnecessary renders
    const contextValue = useMemo(
        () => ({
            name: `${notifications.detection_name || 'Unknown'}`,
        }),
        [kpi],
    );
    return (
        <Context.Provider value={contextValue}>
            {contextHolder}
            <div>
                <Layout>
                    <Header />
                    <Layout>
                        <Sidebar currentKey={"1"} />
                        <Content>
                            <Spin spinning={loading}>
                                <div style={{ padding: '20px' }}>
                                    {/* <Space> */}
                                    <Spin className='loader-custom' style={{ display: loader == true ? "block" : "none", minHeight: "80vh" }} spinning={loader} size="large" >
                                        {/* </Space> */}
                                        <Row span={24}>
                                            <Col style={{ width: "100%" }} sm={12} md={20}>
                                                <Title level={2}>Dashboard</Title>
                                            </Col>

                                            <Col style={{ width: "100%", display: 'flex', alignItems: 'center', gap: '5px' }} sm={12} md={4}>
                                                {/* <div className={'w-[50%]'} >
                                                <BellOutlined style={{ fontSize: '24px' }} />
                                            </div> */}
                                                <Badge count={notificationCount} offset={[-10, 0]}>
                                                    <Button
                                                        type="primary"
                                                        onClick={showDrawer}
                                                    >
                                                        <BellOutlined style={{ fontSize: '24px' }} />
                                                    </Button>
                                                </Badge>
                                                <Button width={'50%'} onClick={() => setIsVisible(true)} className='dashboard_filter-button filter-active'>Filter {Object.keys(queryValues).length > 0 && <span className='filter-span' style={{ color: "red", fontSize: "20px" }} />}</Button></Col>
                                            <CustomModal modalState={modalState} cameraNames={cameraNames} />
                                        </Row>
                                        <Row style={{ marginBottom: '20px' }}>
                                            <Col span={6}>
                                                <Form.Item
                                                    label=""
                                                    name="kpiType"
                                                    rules={[{ required: false, message: 'Please select KPI Type!' }]}
                                                >
                                                    <Select className='w-100' onChange={handleKpiTypeChange} placeholder='All KPIs'>
                                                        {/* <Option value="Weapon Detection">Weapon Detection</Option>
                                                    <Option value="Number Plate">Number Plate</Option> */}
                                                        <Option value="Face Recognition">Face Recognition</Option>
                                                        <Option value="Firesmoke Detection">Fire & Smoke Detection</Option>
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col span={2}>
                                                <Button style={{ width: "100px" }} onClick={onFinishFilter}>Apply</Button>
                                            </Col>
                                        </Row>

                                        {!noData ?
                                            (!loading
                                                ? <Row>
                                                    {csvdata?.map((data, index) => (
                                                        <Col key={index} style={{ padding: '10px' }} sm={24} md={12} lg={6}>
                                                            <AlertCard index={index} data={data} />
                                                        </Col>
                                                    ))}
                                                </Row>
                                                : <></>)
                                            :
                                            <Row className='noData'>No Data Found</Row>
                                        }


                                        {ViewHistory == true ?
                                            <Card
                                                title="Filter by" extra={<Input className="search-bar-custom" placeholder="Search"
                                                    // onKeyDown={test2}
                                                    // onChange={(e) => setSearchField(e.target.value)} 
                                                    suffix={
                                                        <SearchOutlined style={{ fontSize: '20px' }} />} />}>


                                                <Row>
                                                    {a?.map((data, index) => (
                                                        <Col key={index} style={{ padding: '20px' }} sm={24} md={12} lg={6}>
                                                            <AlertCard data={a} />
                                                        </Col>
                                                    ))}
                                                </Row>


                                            </Card>
                                            : null
                                        }

                                        {!noData && <div>
                                            {totalRecords > events?.length && <Row style={{ justifyContent: 'center' }}>
                                                <Button type="primary" onClick={onClickLoadMore}>
                                                    Load More
                                                </Button>
                                            </Row>}
                                        </div>}

                                    </Spin>
                                </div>
                            </Spin>
                        </Content>
                    </Layout>
                </Layout>
                <>
                    <Drawer
                        title="All Notifications"
                        placement="right"
                        onClose={closeDrawer}
                        visible={drawerVisible}
                        width={400}
                    >
                        <List
                            dataSource={[...notificationList].reverse()} // Reverse the notifications array to show latest first
                            renderItem={notification => (
                                <List.Item
                                    key={createUniqueKey(notification)}
                                    onClick={() => {
                                        history.push({
                                            pathname: '/details',
                                            state: { notification }
                                        });
                                        closeDrawer(); // Close drawer after navigating
                                    }}
                                >
                                    <List.Item.Meta
                                        title={`${notification.detection_name} Alert`}
                                        description={`Location: ${notification.location} - ${notification.date_time}`}
                                    />
                                </List.Item>
                            )}
                        />
                    </Drawer>
                </>
            </div>
        </Context.Provider>

    )
}

export default Dashboard
