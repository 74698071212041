import { Button, DatePicker, Form, Modal, Radio, Row, Select, Space } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import "./modals.css"
const CustomModal = ({ modalState, cameraNames }) => {

    const { isVisible, onFinish, onCancel, clearFilters, form, blocks, setFilters } = modalState

    const [uid, setUID] = useState("")

    const { RangePicker } = DatePicker;
    const { Option } = Select;



    const handleClear = () => clearFilters()


    return (
        <Modal className='filter-modal' style={window.screen.width > 900 && { left: 250, top: 40 }} mask={false}
            title={<div className='filter-header'><h4>Filter</h4><Button onClick={handleClear}>Clear Filters</Button></div>}
            closable={null} onCancel={onCancel} visible={isVisible} footer={null} header={null}>
            <Form
                name="Filter-box"
                className="filter-form"
                onFinish={onFinish}
                form={form}
            >



                <Form.Item name="cameraName" label="Camera Name">
                    <Select
                        placeholder="Select Camera Name"
                        onChange={(value) => setFilters(prev => { return { ...prev, cameraName: value } })}
                        allowClear
                    >
                        {cameraNames?.map((name, index) => (
                            <Option key={index} value={name}>{name}</Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Type Kpi"
                    name="kpiType"
                    rules={[{ required: false, message: 'Please select Location!' }]}
                >
                    <Select className='w-100' onChange={value => setFilters(prev => { return { ...prev, kpiType: value } })}>
                        <Option value="Baggage Detection">Baggage Detection</Option>
                        <Option value="Number Plate">Number Plate</Option>
                        <Option value="Face Recognition">Face Recognition</Option>
                        <Option value="Fire&Smoke Detection">Fire & Smoke Detection</Option>
                    </Select>
                </Form.Item>
                <Form.Item name="isCustom" label="Manual Entry"  >
                    <Radio.Group onChange={value => setFilters(prev => { return { ...prev, isCustom: value?.target?.value } })}>
                        <Radio value={true}>Manual Entry</Radio>
                        <Radio value={false}>Detected by AI</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item name="validatedEntry" label="Validate" >
                    <Radio.Group onChange={value => setFilters(prev => { return { ...prev, validatedEntry: value?.target?.value } })}>
                        <Radio value={true}>Validate Entry</Radio>
                        <Radio value={false}>Non Validate Entry</Radio>
                    </Radio.Group>  
                </Form.Item>


                <Form.Item
                    label="Detection Date"
                    name="detectionDate"
                    rules={[{ required: false, message: 'Please select Date/Time!' }]}
                >
                    <DatePicker
                        onChange={value => setFilters(prev => { return { ...prev, detectionDate: moment(value).format('DD/MM/YYYY') } })}
                        disabledDate={(current) => {
                            return current && current > moment(new Date(), "DD-MM-YYYY");
                        }}
                        format="DD-MM-YYYY "
                        className='w-100' />

                </Form.Item>

                <Row style={{ display: "flex", justifyContent: "center" }}>
                    <Button style={{ width: "150px" }} htmlType='submit'>Apply</Button>
                </Row>
            </Form>
        </Modal >
    )
}

export default CustomModal