import React from 'react';
import logo from './logo.svg';
import { Counter } from './features/counter/Counter';
import './App.css';
import Routing from './router/router';
import { onMessage } from 'firebase/messaging';
import firebase from './Firebase/firebase.config';
import { useState } from 'react';

function App() {

  const [notifications, setNotifications] = useState([]);

  // Listen for messages from Firebase
  onMessage(firebase.messaging, (payload) => {
    // Log the custom data your backend sends
    if (payload?.data && payload?.data?.json_data) {
      // Parse the JSON data
      let parsedData = JSON.parse(payload.data.json_data.replace(/'/g, '"'));

      // Update notifications state by appending the new notification
      setNotifications(prevNotifications => [
        ...prevNotifications,
        parsedData // Append the new parsed data to the array
      ]);
    }
  });


  return (
    <div className="App">
      <Routing notifications={notifications} />
      {/* <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <Counter />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <span>
          <span>Learn </span>
          <a
            className="App-link"
            href="https://reactjs.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            React
          </a>
          <span>, </span>
          <a
            className="App-link"
            href="https://redux.js.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Redux
          </a>
          <span>, </span>
          <a
            className="App-link"
            href="https://redux-toolkit.js.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Redux Toolkit
          </a>
          ,<span> and </span>
          <a
            className="App-link"
            href="https://react-redux.js.org/"
            target="_blank"
            rel="noopener noreferrer"
          >
            React Redux
          </a>
        </span>
      </header> */}
    </div>
  );
}

export default App;
