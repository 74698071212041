import apiConfig from "../Environments/environment";
import ErrorHandling from "../ErrorHandling/errorHandling";

import {
  environment,
} from '../Environments/environment';

let {
  apiPath,
} = environment;
apiPath = 'https://app.vidan.ai/api'
// apiPath = 'http://localhost:3000'

const {
  REACT_APP_yoloEndpoint: yoloEndpoint,
} = process.env;

const tokenFromLocalStorage = localStorage.getItem('token');

const header = new Headers({
  'content-type': 'application/json',
})


export const imageHeader = {
  'content-type': 'application/json',
  // 'Authorization': tokenFromLocalStorage
}



// Login API 
export async function bearerToken(obj) {

  let result = await fetch(`${apiPath}/tokens/authenticate`,
    {
      method: 'POST',
      headers: header,
      body: JSON.stringify(obj),
    });

  const handledError = await ErrorHandling(result);
  return handledError;
};

// INTERIM CI/CD/TEST

// Login API 
export async function Login(obj) {

  let result = await fetch(`${apiPath}/login`,
    {
      method: 'POST',
      headers: header,
      body: JSON.stringify(obj),
    });

  const handledError = await ErrorHandling(result);
  return handledError;
};


// Login API 
export async function getEventsApi(apiData) {

  let { offset, limit, queryValues } = apiData;

  const tokenFromLocalStorage = localStorage.getItem('token');

  let result = await fetch(`${apiPath}/event/all?offset=${offset}&limit=${limit}`,


    {
      method: 'PUT',
      headers: {
        'content-type': 'application/json',
        'Authorization': tokenFromLocalStorage
      },
      body: JSON.stringify(queryValues)
    },

  );

  const handledError = await ErrorHandling(result);
  return handledError;
};

export async function getAnalytics(obj) {
  const tokenFromLocalStorage = localStorage.getItem('token');
  let result = await fetch(`${apiPath}/analytics`,
    {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'Authorization': tokenFromLocalStorage
      },
    });
  const handledError = await ErrorHandling(result);
  return handledError;
};

// het events by id 
export async function getEventsApiByID(obj) {

  const tokenFromLocalStorage = localStorage.getItem('token');

  let result = await fetch(`${apiPath}/event?id=${obj}`,



    {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'Authorization': tokenFromLocalStorage
      },
    });

  const handledError = await ErrorHandling(result);
  return handledError;
};



// delete events
export async function deleteEventApi(obj) {

  const tokenFromLocalStorage = localStorage.getItem('token');

  let result = await fetch(`${apiPath}/validate_entry`,


    {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'Authorization': tokenFromLocalStorage
      },
      body: JSON.stringify(obj)
    });

  const handledError = await ErrorHandling(result);
  return handledError;
};



// Create events
export async function createEventApi(data) {

  const tokenFromLocalStorage = localStorage.getItem('token');


  let result = await fetch(`${apiPath}/manual_entry`,
    {
      method: 'POST',
      headers: {
        // 'content-type': 'multipart/form-data',
        'Authorization': tokenFromLocalStorage
      },
      body: data
    });

  const handledError = await ErrorHandling(result);
  return handledError;
};



// Create events
export async function uploadImageApi(data) {

  const tokenFromLocalStorage = localStorage.getItem('token');

  const useFile = data instanceof File
    ? data
    : data.originFileObj

  const formData = new FormData();

  formData.append('file', useFile);
  formData.append('blob', '');
  formData.append('location', '');
  formData.append('modeltype', '');


  // formData.append('model_choice', 'cap-uncapped');
  // headers.append('Origin','http://localhost:3000');

  let result = await fetch(yoloEndpoint,
    {
      method: 'POST',
      headers: {},
      body: formData
    });
  let message = await result.text();

  return {
    success: true,
    message,
  };
};

//Validate API
export async function validateUID(body) {

  const tokenFromLocalStorage = localStorage.getItem('token');

  let result = await fetch(`${apiPath}/validate_entry`,

    {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'Authorization': tokenFromLocalStorage
      },
      body: JSON.stringify(body)
    },

  );

  const handledError = await ErrorHandling(result);
  return handledError;
};

//Validate API
export async function blockOptionsAPI() {

  const tokenFromLocalStorage = localStorage.getItem('token');

  let result = await fetch(`${apiPath}/event/block-options`,

    {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'Authorization': tokenFromLocalStorage
      },
    },

  );

  const handledError = await ErrorHandling(result);
  return handledError;
};



