import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Login } from '../../apiinteraction/apiinteraction';

const initialState = {
  payload: {},
  loading: false,
};



export const LoginAsync = createAsyncThunk('login/fetchUser',
  async (values) => {
    try {
      const response = await Login(values);

      const { message: { accessToken = null } = {} } = response;

      if (accessToken)
        localStorage.setItem('token', accessToken);

      // The value we return becomes the `fulfilled` action payload
      return response;
    }
    catch (error) {
      return error;
    }

  }
)

export const auth = createSlice({
  name: 'login',
  initialState,

  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(LoginAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(LoginAsync.fulfilled, (state, action) => {
        state = action.payload;
        return state;
      })
      .addCase(LoginAsync.rejected, (state, action) => {
        state.payload = action.payload;
        return action.payload;
      });
  },
});

export const selectUserData = (state) => state.login.userData;



export default auth.reducer;
