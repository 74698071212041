// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging } from "firebase/messaging";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBgnA1OPazdE8IsIpkwLbu8DQW9tR6UQVc",
    authDomain: "vidan-62c1f.firebaseapp.com",
    projectId: "vidan-62c1f",
    storageBucket: "vidan-62c1f.appspot.com",
    messagingSenderId: "377487307225",
    appId: "1:377487307225:web:c10f7e121ce0b4288e29c8",
    measurementId: "G-TYGSH3XMJT"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const messaging = getMessaging(app);


const firebase = { app, messaging }
export default firebase