import { Card, Col, Layout, Row, Spin, Statistic, Typography } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import React, { useEffect, useState } from 'react'
import Sidebar from '../../component/siderbar/sidebar'
import { Header } from '../../component/header/header'
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons'
import { getAnalytics } from '../../apiinteraction/apiinteraction'

const Analytics = () => {
    const { Title, Text } = Typography;

    const [baggageDetection, setbaggageDetection] = useState(null);
    const [faceRecognition, setFaceRecognition] = useState(null);
    const [numberPlate, setNumberPlate] = useState(null);
    const [firesmokeDetection, setFireSmokeDetection] = useState(null);
    const [Data, setData] = useState(null);

    const [loading, setLoading] = useState(true);
    const loadCardValues = async () => {
        try {
            const respnse = await getAnalytics();
            setData(respnse?.message)
            // setbaggageDetection(baggageDetection);

            // setFaceRecognition(faceRecognition);
            // setNumberPlate(numberPlate);
        } catch (error) {
            console.error('Failed to fetch card values:', error);
        } finally {
            setLoading(false);
        }
    };
    console.log(Data,'aaaa')

    useEffect(() => {
        loadCardValues();
    }, []);
    return (
        <div>

            <Layout>
                <Header />

                <Layout>
                    <Sidebar currentKey={"4"} />
                    <Content style={{ padding: '20px' }}>
                        <Row>
                        <Title level={2}>Analytics</Title>
                        </Row>

                        <Row gutter={16} style={{ marginTop: '20px' }}>
                            <Col span={8}>
                                <Card bordered={false} style={{ borderRadius: '10px', boxShadow: '3px 3px 12px 5px lightgray',padding:'10px' }}>
                                    <Title level={4}>Number Plate Recognition</Title>
                                    <Text  style={{fontWeight:'500'}} level={5}>No. of Vehicle Detections</Text>

                                    <Row>
                                        <Col md={9}>
                                            
                                            <Statistic
                                                title="Past 90 Days"
                                                value={Data?.Number_Plate[0]}
                                                precision={1}
                                                valueStyle={{ color: '' }}
                                                suffix=""
                                            />
                                        </Col>
                                        <Col md={9}  >
                                            
                                            <Statistic
                                                title="Past 30 Days"
                                                value={Data?.Number_Plate[1]}
                                                precision={1}
                                                valueStyle={{ color: '' }}
                                                suffix=""
                                            />
                                        </Col>
                                        <Col md={6} >
                                            <Statistic
                                                title="Today"
                                                value={Data?.Number_Plate[2]}
                                                precision={1}
                                                valueStyle={{ color: '#3f8600' }}
                                                prefix={<ArrowUpOutlined />}
                                                suffix=""
                                            />
                                        </Col>
                                        <Col md={9} style={{marginTop:"10px"}}>
                                        <Text style={{fontWeight:'500'}} level={5}>No. of NP Recognitions</Text>

                                            <Statistic
                                                title="Past 90 Days"
                                                value={Data?.Number_Plate[3]}
                                                precision={1}
                                                valueStyle={{ color: '' }}
                                                suffix=""
                                            />
                                        </Col>
                                        <Col md={9}  style={{marginTop:"33px"}}>
                                            
                                            <Statistic
                                                title="Past 30 Days"
                                                value={Data?.Number_Plate[4]}
                                                precision={1}
                                                valueStyle={{ color: '' }}
                                                suffix=""
                                            />
                                        </Col>
                                        <Col md={6}  style={{marginTop:"33px"}} >
                                            <Statistic
                                                title="Today"
                                                value={Data?.Number_Plate[5]}
                                                precision={1}
                                                valueStyle={{ color: '#3f8600' }}
                                                prefix={<ArrowUpOutlined />}
                                                suffix=""
                                            />
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                            <Col span={8}>
                                <Card style={{ borderRadius: '10px', boxShadow: '3px 3px 12px 5px lightgray' ,padding:'10px'}} bordered={false}>
                                    <Title level={4}>Face Recognition</Title>
                                    <Text  style={{fontWeight:'500'}} level={5}>No. of Person Detections</Text>

                                    <Row>
                                        <Col md={9}>
                                            <Statistic
                                                title="Past 90 Days"
                                                value={Data?.Face_Recognition[0]}
                                                precision={1}
                                                valueStyle={{ color: '' }}
                                                suffix=""
                                            />
                                        </Col>
                                        <Col md={9} >
                                            
                                            <Statistic
                                                title="Past 30 Days"
                                                value={Data?.Face_Recognition[1]}
                                                precision={1}
                                                valueStyle={{ color: '' }}
                                                suffix=""
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <Statistic
                                                title="Today"
                                                value={Data?.Face_Recognition[2]}
                                                precision={1}
                                                valueStyle={{ color: '#3f8600' }}
                                                prefix={<ArrowUpOutlined />}
                                                suffix=""
                                            />
                                        </Col>
                                        <Col md={9}  style={{marginTop:"10px"}} >
                                        <Text style={{fontWeight:'500'}} level={5}>No. of Face Recognitions</Text>

                                            <Statistic
                                                title="Past 90 Days"
                                                value={Data?.Face_Recognition[3]}
                                                precision={1}
                                                valueStyle={{ color: '' }}
                                                suffix=""
                                            />
                                        </Col>
                                        <Col md={9}  style={{marginTop:"33px"}}>
                                            
                                            <Statistic
                                                title="Past 30 Days"
                                                value={Data?.Face_Recognition[4]}
                                                precision={1}
                                                valueStyle={{ color: '' }}
                                                suffix=""
                                            />
                                        </Col>
                                        <Col md={6}  style={{marginTop:"33px"}} >
                                            <Statistic
                                                title="Today"
                                                value={Data?.Face_Recognition[5]}
                                                precision={1}
                                                valueStyle={{ color: '#3f8600' }}
                                                prefix={<ArrowUpOutlined />}
                                                suffix=""
                                            />
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        {/* </Row>
                        <Row gutter={16} style={{ marginTop: '20px' }}> */}
                            <Col span={8}>
                                <Card bordered={false} style={{ borderRadius: '10px', boxShadow: '3px 3px 12px 5px lightgray',padding:'10px' }}>
                                    <Title level={4}>Baggage Detection</Title>
                                    <Text  style={{fontWeight:'500'}} level={5}>No. of Person Detections</Text>

                                    <Row>
                                    <Col md={9}>
                                            <Statistic
                                                title="Past 90 Days"
                                                value={Data?.Baggage_Detection[0]}
                                                precision={1}
                                                valueStyle={{ color: '' }}
                                                suffix=""
                                            />
                                        </Col>
                                        <Col md={9}  >
                                            
                                            <Statistic
                                                title="Past 30 Days"
                                                value={Data?.Baggage_Detection[1]}
                                                precision={1}
                                                valueStyle={{ color: '' }}
                                                suffix=""
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <Statistic
                                                title="Today"
                                                value={Data?.Baggage_Detection[2]}
                                                precision={1}
                                                valueStyle={{ color: '#3f8600' }}
                                                prefix={<ArrowUpOutlined />}
                                                suffix=""
                                            />
                                        </Col>
                                       

                                        <Col md={9}  style={{marginTop:"10px"}} >
                                        <Text style={{fontWeight:'500'}} level={5}>No. of Baggage Detections</Text>

                                            <Statistic
                                                title="Past 90 Days"
                                                value={Data?.Baggage_Detection[3]}
                                                precision={1}
                                                valueStyle={{ color: '' }}
                                                suffix=""
                                            />
                                        </Col>
                                        <Col md={9}  style={{marginTop:"33px"}}>
                                            
                                            <Statistic
                                                title="Past 30 Days"
                                                value={Data?.Baggage_Detection[4]}
                                                precision={1}
                                                valueStyle={{ color: '' }}
                                                suffix=""
                                            />
                                        </Col>
                                        <Col md={6}  style={{marginTop:"33px"}} >
                                            <Statistic
                                                title="Today"
                                                value={Data?.Baggage_Detection[5]}
                                                precision={1}
                                                valueStyle={{ color: '#3f8600' }}
                                                prefix={<ArrowUpOutlined />}
                                                suffix=""
                                            />
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                        <Row gutter={16} style={{ marginTop: '20px' }}>
                            <Col span={8}>
                                <Card bordered={false} style={{ borderRadius: '10px', boxShadow: '3px 3px 12px 5px lightgray',padding:'10px' }}>
                                    <Title level={4}>Fire & Smoke Detection</Title>
                                    <Text  style={{fontWeight:'500'}} level={5}>No. of Fire Detections</Text>

                                    <Row>
                                    <Col md={9}>
                                            <Statistic
                                                title="Past 90 Days"
                                                value={Data?.FireSmoke_Detection[0]}
                                                precision={1}
                                                valueStyle={{ color: '' }}
                                                suffix=""
                                            />
                                        </Col>
                                        <Col md={9}  >
                                            
                                            <Statistic
                                                title="Past 30 Days"
                                                value={Data?.FireSmoke_Detection[1]}
                                                precision={1}
                                                valueStyle={{ color: '' }}
                                                suffix=""
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <Statistic
                                                title="Today"
                                                value={Data?.FireSmoke_Detection[2]}
                                                precision={1}
                                                valueStyle={{ color: '#3f8600' }}
                                                prefix={<ArrowUpOutlined />}
                                                suffix=""
                                            />
                                        </Col>
                                       

                                        <Col md={9}  style={{marginTop:"10px"}} >
                                        <Text style={{fontWeight:'500'}} level={5}>No. of Smoke Detections</Text>

                                            <Statistic
                                                title="Past 90 Days"
                                                value={Data?.FireSmoke_Detection[3]}
                                                precision={1}
                                                valueStyle={{ color: '' }}
                                                suffix=""
                                            />
                                        </Col>
                                        <Col md={9}  style={{marginTop:"33px"}}>
                                            
                                            <Statistic
                                                title="Past 30 Days"
                                                value={Data?.FireSmoke_Detection[4]}
                                                precision={1}
                                                valueStyle={{ color: '' }}
                                                suffix=""
                                            />
                                        </Col>
                                        <Col md={6}  style={{marginTop:"33px"}} >
                                            <Statistic
                                                title="Today"
                                                value={Data?.FireSmoke_Detection[5]}
                                                precision={1}
                                                valueStyle={{ color: '#3f8600' }}
                                                prefix={<ArrowUpOutlined />}
                                                suffix=""
                                            />
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                    </Content>
                </Layout>
            </Layout>

        </div >
    )
}
export default Analytics