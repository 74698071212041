import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createEventApi, deleteEventApi, getEventsApi, getEventsApiByID, uploadImageApi } from '../../apiinteraction/apiinteraction';

const initialState = {
  payload: {},
  loading: false,
};



export const uploadImage = createAsyncThunk(
  'upladImage',
  async (data) => {
    try {
      const response = await uploadImageApi(data);
      return response;
    }
    catch (error) {
      return error;
    }
  }
);



export const createEvent = createAsyncThunk(
  'createEvent',
  async (data) => {
    try {
      const response = await createEventApi(data);
      // console.log(response)
      // The value we return becomes the `fulfilled` action payload
      return response;
    }
    catch (error) {
      return error;
    }
  }
);



export const getEventsByID = createAsyncThunk(
  'getEventsByID',
  async (obj) => {
    try {
      const response = await getEventsApiByID(obj.id);
      // console.log(response)
      // The value we return becomes the `fulfilled` action payload
      return response;
    }
    catch (error) {
      return error;
    }
  }
);


export const deleteEvent = createAsyncThunk(
  'deleteEvents',
  async (obj) => {
    try {
      const response = await deleteEventApi(obj);
      // The value we return becomes the `fulfilled` action payload
      return response;
    }
    catch (error) {
      return error;
    }
  }
);


export const getAllEvents = createAsyncThunk('login/fetchUser',
  async (values) => {
    try {
      console.log(values)
      const response = await getEventsApi(values);
      // The value we return becomes the `fulfilled` action payload
      return response;
    }
    catch (error) {
      return error;
    }

  }
)

export const getEvents = createSlice({
  name: 'getEvents',
  initialState,

  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllEvents.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllEvents.fulfilled, (state, action) => {
        state = action.payload;
        return state;
      })
      .addCase(getAllEvents.rejected, (state, action) => {
        state.payload = action.payload;
        return action.payload;
      });
  },
});

export const selectUserData = (state) => state.getEvents.events;



export default getEvents.reducer;
