import { Image } from 'antd'
import React from 'react'
import "./Slideshow.css"
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import defaultImage from "../../assets/images/broken-image.png"

const Slideshow = ({ images = [2, 3, 4, 5] }) => {

    console.log('Hello: ', { images })
    return (
        <Carousel autoPlay>
            {images
                .map((image, index) => (
                    <Image className='carrousel-container-image' key={index} preview={true} alt="No Image found" src={image ? require(`../../static/Images/${image}.jpg`) : ''} height={450}  />
                ))
            }
        </Carousel>
    )
}

export default Slideshow