import { Button, Checkbox, Col, Form, Input, message, Modal, notification, Row, Select } from 'antd'
import React, { useState } from 'react'
import { validateUID } from '../../apiinteraction/apiinteraction'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const ValidateModal = ({ isVisible, onCancel, data, block_options = [] }) => {

    const [form] = Form.useForm()
    const [selectedUID, setSelectedUID] = useState('')
    const [uidCheckbox, setUidCheckbox] = useState(null)
    const { Option } = Select;

    const history = useHistory()
    const onChange = (type, { data, uid }) => {


        if (uidCheckbox == type) {
            setUidCheckbox(null)
            setSelectedUID(null)
            return form.setFieldsValue({ uid: "" })
        }
        setUidCheckbox(type)
        form.setFieldsValue({ uid })
        setSelectedUID(data)

    }

    console.log(data, 'aaaa')
    const handleValidtion = async (values = {}) => {
        let body = {

            eventId: data?.result?.id,
            trackId: data?.trackId,
            cameraName: data?.cameraName,
            kpiType: data?.kpiType,
            validate: 'True',
            // image: data?.result?.uidImagePath ? data?.result?.uidImagePath : (selectedUID?.filePathURL || null),
            ...values
        }
        let response = await validateUID(body)
        if (response.success === true) {
            onCancel()
            message.success('Successfully Validated!')
            history.push({
                pathname: '/dashboard',
            })
            // reloadPage(!reloadFlag)
        }
    }

    return (
        <Modal className='filter-modal' mask={false}
            title={<div className='filter-header'><h4>Validate UID</h4></div>}
            closable={null} onCancel={onCancel} visible={isVisible} footer={null} header={null}>

            <Row>
                {data?.before?.length ?
                    <Col className='validate_input_container' span={12}>
                        <Checkbox checked={uidCheckbox === 1 && true} onChange={() => onChange(1, { data, uid: data?.before[0]?.uid })}>
                            {data?.before[0]?.uid}
                        </Checkbox>
                    </Col>
                    : <></>
                }
                {data?.after?.length ?
                    <Col className='validate_input_container' span={12}>
                        <Checkbox checked={uidCheckbox === 2 && true} onChange={() => onChange(2, { data, uid: data?.after[0]?.uid })}>
                            {data?.after[0]?.uid}
                        </Checkbox>
                    </Col> : <></>
                }
            </Row>

            {/* <form onSubmit={handleValidtion}>
                <Row className='validate_input_container'>
                    <input required className='validate_input' value={selectedUID.uid} type='text' onChange={(e) => { setUidCheckbox(0); setSelectedUID({ uid: e.target.value }) }} />
                </Row>
                <Row className='validate_input_container'>
                    <Button htmlType='submit'>Validate UID</Button>
                </Row>
            </form>  */}

            <Form className='mt-3' form={form} onFinish={handleValidtion}>


                <Form.Item
                    label="Detection Name"
                    name='detectionName'
                    initialValue={data?.detectionName}
                    rules={[
                        {
                            required: false,
                            message: 'Detection is required!',
                        },
                    ]} >
                    <Input />
                </Form.Item>


                <Row className='validate_input_container'>
                    <Button htmlType='submit'>Validate UID</Button>
                </Row>
            </Form>

        </Modal>
    )
}

export default ValidateModal