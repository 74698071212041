import React, { useState } from 'react'
import { Layout, Menu, Image, Row, Col, PageHeader, Button, Input, message, Dropdown, Descriptions, Typography, notification } from 'antd';
import { DownOutlined, SearchOutlined, UserOutlined } from '@ant-design/icons';
import Notification from '../../assets/images/notification.png';
import Logo from '../../assets/images/logoHeader.png'
import user from '../../assets/images/user.png'

import './header.css'

export const Header = (props) => {

    const { Title, Text } = Typography;

    const menu = (
        <Menu onClick={handleMenuClick}>
            <Menu.Item key="1" icon={<UserOutlined />}>
                1st menu item
            </Menu.Item>
            <Menu.Item key="2" icon={<UserOutlined />}>
                2nd menu item
            </Menu.Item>
            <Menu.Item key="3" icon={<UserOutlined />}>
                3rd menu item
            </Menu.Item>
        </Menu>
    );


    function handleButtonClick(e) {
        message.info('Click on left button.');
        console.log('click left button', e);
    }

    function handleMenuClick(e) {
        message.info('Click on menu item.');
        console.log('click', e);
    }

    return (

        <div className="site-page-header-ghost-wrapper      ">
            {/* <PageHeader
                style={{ background: 'white' }}
                ghost={false}
                title={<Title level={3}><Image preview={false} src={Logo} /></Title>}
            >
            </PageHeader> */}
        </div>

    )
}

// export Header
